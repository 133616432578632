.create-modal-custom {
  @apply !w-[680px];

  .create-seed-progress-custom {
    .elf-progress-outer {
      @apply flex h-[8px];
    }
    .elf-progress-inner {
      @apply bg-[#312D3F] rounded-none;
    }
    .elf-progress-bg {
      @apply rounded-none;
    }
  }

  .create-seed-progress-custom.elf-progress-status-exception {
    .elf-progress-bg {
      @apply !bg-[#BE283A];
    }
  }

  .progress-last {
    .elf-progress-inner {
      @apply !rounded-l-none !rounded-r-[100px];
    }
  }
  .progress-first {
    .elf-progress-inner {
      @apply !rounded-r-none !rounded-l-[100px];
    }
  }

  .progress-last.progress-first {
    .elf-progress-inner {
      @apply !rounded-l-[100px] !rounded-r-[100px];
    }
  }

  .steps-number {
    @apply w-[24px] h-[24px] text-white font-medium text-center leading-[24px] text-[12px] border-[1px] border-white border-solid rounded-[50%];
  }

  .steps-icon {
    svg {
      @apply block;
    }
    &::after {
      @apply md:hidden;

      content: '';
      position: absolute;
      left: 12px;
      top: 24px;
      bottom: -32px;
      width: 2px;
      background-color: #312d3f;
    }
    circle {
      @apply stroke-[#312D3F] md:stroke-white;
    }
    .elf-progress-text {
      @apply text-white text-[12px] font-medium;
    }
    .elf-progress-circle-path {
      @apply !stroke-primary-color;
    }
    .progress-icon-disabled {
      circle {
        @apply !stroke-[#796f94];
      }
      .elf-progress-text {
        @apply !text-[#796f94];
      }
    }
  }

  .steps-icon.progress-last {
    &::after {
      @apply !hidden;
    }
  }
  .steps-icon.progress-success {
    &::after {
      @apply bg-[#8B60F7];
    }
  }
}
